/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';

import 'url-search-params-polyfill';
/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
* with the following flag, it will bypass `zone.js` patch for IE/Edge
*/
(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window;

// declare global {
//     interface Array<T> {
//         includes(search: any, start): boolean;
//     }

//     interface String {
//         padStart(targetLength, padString)
//     }
// }

// if (!String.prototype.padStart) {
//     String.prototype.padStart = function padStart(targetLength, padString) {
//         // tslint:disable-next-line: no-bitwise
//         targetLength = targetLength >> 0;
//         padString = String((typeof padString !== 'undefined' ? padString : ' '));
//         if (this.length > targetLength) {
//             return String(this);
//         } else {
//             targetLength = targetLength - this.length;
//             if (targetLength > padString.length) {
//                 padString += padString.repeat(targetLength / padString.length);
//             }
//             return padString.slice(0, targetLength) + String(this);
//         }
//     };
// }

// if (!Array.prototype['includes']) {
//     Array.prototype.includes = function (search: any, start) {
//         'use strict';

//         if (search instanceof RegExp) {
//             throw TypeError('first argument must not be a RegExp');
//         }
//         if (start === undefined) { start = 0; }
//         return this.indexOf(search, start) !== -1;
//     };
// }

if (!("path" in Event.prototype))
    Object.defineProperty(Event.prototype, "path", {
        get: function () {
            var path = [];
            var currentElem = this.target;
            while (currentElem) {
                path.push(currentElem);
                currentElem = currentElem.parentElement;
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
                path.push(document);
            if (path.indexOf(window) === -1)
                path.push(window);
            return path;
        }
    });

(1.005).toFixed(2) == "1.01" || (function (prototype) {
    var toFixed = prototype.toFixed;

    prototype.toFixed = function (fractionDigits) {
        var split = this.toString().split('.');
        const decimals = split[1];
        var number = +(!split[1] ? split[0] : split.join('.')
            + (decimals.charAt(2)
                && Number(decimals.charAt(2)) >= 5 ? '1' : '0'));
        return toFixed.call(number, fractionDigits);
    }
}(Number.prototype));